@import '../AppVars';

.generic-table {
  height: 100%;
  overflow: auto; // req'd to scroll body
  font-family: 'PT Sans', sans-serif;
  .messages {
    .error {
      font-size: 20px;
      color: $sunwize-red;
    }

    .info {
      color: $green;
    }

    .warning {
      color: $warning-color;
    }
  }

  .table-responsive {
    // very important — prevent react-select drop-down menu from being completely hidden!
    overflow: visible;
    height: 100%;
    table {
      height: 100%;

      th,
      td {
        padding: 0.25rem;
      }

      thead tr:nth-child(1) th {
        background-color: $sunwize-panel-background-color;
        z-index: 2;
        position: sticky; // prevent header from scrolling
        top: 0; // prevent header from scrolling

        th {
          color: $sunwize-black;
          box-shadow: inset 0 1px 0 #dee2e6, inset 0 -1px 0 #dee2e6;
          border-style: hidden;
        }
      }

      tbody {
        overflow: auto; // req'd to scroll body
      }
    }
  }

  .button,
  .loader-button {
    color: white;
    font-weight: bold;
    border-color: black;
    font-size: 11px;
    margin-right: 5px;
    padding: 2px;

    &:hover {
      color: $sunwize-yellow;
    }
  }

  .banner-items {
    position: absolute;
    display: flex;
    align-items: center;
    svg {
      color: white;
    }
    .cog-wrapper {
      display: flex;
      align-items: center;
      svg {
        height: 26px;
      }
    }

    .banner-item,
    .dropdown-toggle {
      button {
        font-size: app_scale(10);
        color: white;
        border: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    button {
      font-size: app_scale(20);
      padding: app_scale(2);
      background-color: transparent;
      border: none;
      &:hover {
        border-bottom-width: 1px;
        border-bottom-color: white;
        border-bottom-style: solid;
        border-radius: 0;
      }
    }

    button.dropdown-toggle {
      height: app_scale(30);
      width: app_scale(30);

      // lose the down caret
      &::after {
        display: none;
      }
    }
  }

  .footer {
    height: fit-content;

    .page-number {
      margin-right: 10%;
    }

    label,
    label input,
    .page-number,
    .dropdown-item {
      color: $sunwize-blue;
      border-color: $sunwize-blue;
    }

    .dropdown-menu.show {
      min-width: 4rem;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;

    .page-controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 5px;

      label {
        margin-bottom: 0;
        margin-left: 4px;
      }

      label input {
        width: 2.5rem;
        margin: 0 5px;
        padding: 2px;
        border-width: 1px;
        border-radius: 4px;
      }
    }
  }
}
