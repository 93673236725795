.profile-picture {
  position: relative;
  .superuser-overlay {
    position: absolute;
    top: 0;
    left: 0;
  }
  svg {
    width: 100%;
    height: 100%;
    text {
      font-size: 16px;
    }
  }
}
