@import '../AppVars';
.device-select__multi-value__label {
  span {
    font-weight: bold;
  }
}

.event-definition-form {
  font-family: PosterGothicATF, sans-serif;
  .modal-dialog.modal-lg {
    max-width: 800px !important; // override stupid bootstrap
  }
  .alert-danger {
    padding: 4px;
    margin: 0;
    line-height: 0.8;
  }
  .form-label {
    font-weight: bold;
  }
  .form-group {
    margin: 5px;
  }
  .form-column.device-selections {
    flex: 1 1 auto;
  }
  .form-row {
    margin: 2px;
    border-width: 1px;
    border-style: solid;
    border-color: lightblue;
    display: flex;
    width: 100%;
    border-radius: 2px;
    font-size: 15px;

    .eventType,
    .description,
    .name,
    .registerAddress,
    .mask,
    .shift,
    .factor,
    .operand {
      flex: 1 1 auto;
    }
    &.last {
      align-items: center;
      justify-content: space-evenly;
      .operand {
        flex: 0 0 auto;
      }
    }
  }
  select {
    width: fit-content;
  }
  Button {
    background-color: $sunwize-blue;
    color: white;
    margin-top: 8px;
    font-size: 12px;
    background-image: $blue-button-gradient;
    padding: 0 10px;
    border-radius: 5px;
    font-family: PosterGothicATF, sans-serif;
    font-weight: bold;
    box-shadow: $cards-box-shadow;
  }
  .condition {
    display: flex;
    flex-wrap: wrap;
  }
  .button-row {
    display: flex;
    justify-content: space-evenly;
  }
  .form-control {
    display: flex;
    align-items: stretch;
    &.select {
      width: fit-content;
    }
    padding: 2px;
    div.label {
      margin: auto;
      text-align: center;
    }
    label {
      font-weight: bold;
      margin: 0 5px;
    }
    input,
    textarea {
      flex: 1 1 auto;
    }
  }
  .operand-wrapper {
    width: fit-content;
    position: relative;
    span {
      color: gray;
      position: absolute;
      right: 4px;
      top: 3px;
    }
    input {
      width: 100px;
    }
  }
  .form-group.shift {
    position: relative;
    span {
      font-size: 18px;
      font-weight: 500;
      position: absolute;
      right: 10px;
      top: 26px;
    }
  }
  .is-checked .form-check-label {
    font-weight: bold;
  }
  .react-select {
    .react-select__input-container {
      display: none;
    }
    .react-select__indicator,
    .react-select__value-container {
      padding: 0;
    }
    .react-select__control {
      min-height: 0;
    }
  }
  .event-type-icon svg {
    height: 24px;
  }
}
