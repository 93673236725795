@import 'src/AppVars';

.systems-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  .banner-items {
    right: 0;
    top: app_scale(19);
  }

  .react-autosuggest__container {
    font-family: 'PT Sans', sans-serif;

    ul {
      list-style-type: none;
    }

    input.react-autosuggest__input {
      font-family: 'PT Sans', sans-serif;
    }

    .suggestion {
      width: fit-content;
      display: flex;
      flex-wrap: wrap;
      border-color: $sunwize-blue;
      border-style: solid;
      border-width: 2px;
      border-radius: 4px;
      margin: 5px;
      padding: 2px;
      box-shadow: $box-shadow;

      .name-and-photo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          border-radius: 50%;
          height: app_scale(30);
        }

        .system-name {
          span {
            font-weight: bold;
            color: white;
            background-image: $blue-gradient;
          }
          padding: 0 app_scale(5);
          font-weight: bold;
        }
      }

      .field {
        box-shadow: $box-shadow;
        margin: 5px;
        border-color: $sunwize-blue;
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;
        padding-left: app_scale(5);
        background-image: linear-gradient(90deg, #eeeeee, white);
        display: flex;
        flex-direction: column;

        .name {
          padding: 0 app_scale(5);
          font-weight: bold;
        }

        .value {
          padding: 0 app_scale(5);

          span {
            font-weight: bold;
            color: white;
            background-image: $blue-gradient;
          }
        }
      }
    }
  }

  .new-user-form {
    .accounts {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .deployment-cell {
    text-align: center;

    .anchor-button,
    .deployment-status {
      background-color: #00000000;
    }

    .deployed-by {
      display: flex;
      justify-content: center;
      align-items: baseline;
    }

    hr {
      margin: 5px;
    }
  }

  .alert {
    margin: 5px;
  }
}
