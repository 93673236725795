@import '../AppVars';

//sunwize styled components
.content-header {
  background-image: linear-gradient(90deg, #67819c, #7498af);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: app_scale(60);
  flex: 0 0 auto;

  .header-title {
    font-size: app_scale(40);
    font-weight: bold;
    color: white;
    padding-left: app_scale(40);
    margin-bottom: app_scale(10);
    line-height: 1;
  }
}
.content-body {
  flex: 1 1 auto;
  min-height: 0;
}
