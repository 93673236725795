@import '../AppVars';

@function scale_($factor) {
  @return app_scale($factor * 1);
}

div.cognito-status {
  text-align: center;

  .is-link {
    a {
      margin: 4px auto auto auto;
    }

    hr {
      margin: 4px;
    }
  }
}

.superusers-page {
  height: 100%;

  .superusers-page-banner {
    display: flex;
    align-items: center;
    height: app_scale(60);
    background-image: $blue-button-gradient;
    box-shadow: $box-shadow;
    padding-left: app_scale(40);

    .its-a-bird-its-a-plane-its {
      width: 60px;
      color: $sunwize-panel-background-color;
    }

    .header-title {
      width: 100%;
      font-size: app_scale(40);
      font-weight: bold;
      color: white;
      line-height: 1;
      padding-left: app_scale(5);
    }
  }

  .invite-new-superuser {
    padding: 10px 0 40px 0;
    width: 90%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;

    button {
      display: flex;
      background-image: $blue-button-gradient;
      color: $sunwize-panel-background-color;
      border-radius: 6px;
      font-size: 12px;
      padding: 4px;

      svg {
        width: 20px;
        margin-right: 4px;
      }
    }
  }

  div.profile-picture {
    svg {
      height: 50px;
      width: 50px;
    }
  }

  .new-user-form {
    .accounts {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .remove-superuser,
  .remove-definition {
    color: darkred;
  }

  .superusers-page-button-bar {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding-left: scale_(55);
    border-bottom: #80808050 1px solid;

    button {
      color: $sunwize-black;
      background-color: transparent;
      padding: 0 scale_(2);
      font-size: scale_(20);
      border: none;
      box-shadow: none;
      border-right: 0;
      margin-right: scale_(20);

      &.is-active,
      &:hover {
        color: $sunwize-black;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  .superusers-panel,
  .event-definitions-panel,
  .companies-panel {
    display: none;
    &.selected-panel {
      display: block;
    }
  }

  .selected-panel {
    height: 100%;
    overflow: hidden;

    .selected-panel-body {
      color: rgba(59, 59, 59, 0.5);
      font-size: 80pt;
      width: fit-content;
      line-height: 1;
      margin-top: 80pt;
      transform: rotate(-10deg);
    }
  }

  .superusers-panel {
    height: 100%;

    .banner-items {
      display: none;
    }

    table {
      width: 90%;
      margin: auto;

      th:last-of-type {
        visibility: hidden;
      }

      td {
        vertical-align: middle;
      }
    }
  }

  .event-definitions-panel {
    height: 100%;
    margin: 0 10px;
    font-size: 15px;

    button {
      margin: 0 5px;
    }

    select {
      margin: 0 5px;
      font-family: PosterGothicATF, sans-serif;
    }

    .button-bar {
      display: flex;
      margin: 10px;
      width: 100%;
      align-items: stretch;
      justify-content: flex-end;

      .search-bar,
      select,
      .search-bar-frame {
        flex: 1 1 auto;
      }

      button.create {
        font-size: 12px;
        color: white;
        background-image: $blue-button-gradient;
        padding: 4px;
        margin-right: 45px;
        border-radius: 5px;
        font-family: PosterGothicATF, sans-serif;
        font-weight: bold;
        box-shadow: $cards-box-shadow;
        display: flex;
        align-items: center;

        svg {
          width: 20px;
          margin-right: 4px;
        }
      }
    }

    .generic-table {
      .banner-items {
        position: absolute;
        right: 6px;
        top: 100px;

        svg {
          color: $sunwize-blue;
        }
      }

      .table-responsive {
        .bell,
        .exclamation-point,
        .check-mark,
        .info-icon {
          width: 30px;
        }

        table {
          background-color: white;
          border-collapse: separate;
        }

        thead {
          font-family: PosterGothicATF, sans-serif;
          font-weight: normal;

          th {
            padding: 0;
            margin: 0;
          }
        }

        tbody tr {
          $row-shadow: 0 0 4px 0 rgba(0, 0, 0, 50%), 0 4px 4px 0 rgba(0, 0, 0, 10%);
          border-radius: 10px;
          box-sizing: border-box;
          box-shadow: $row-shadow;

          td {
            border-collapse: separate;
            border-style: none;
            vertical-align: baseline;

            &:first-of-type {
              padding: 10px;
              font-weight: bold;
            }
          }
        }

        button.edit {
          color: white;
          background-image: $blue-gradient;
          font-size: 12px;
          font-family: PosterGothicATF, sans-serif;
          font-weight: bold;
          padding: 3px 10px;
          border-radius: 5px;
          box-shadow: $cards-box-shadow;
        }

        .value-wrapper {
          display: flex;

          div {
            margin-top: auto;
            margin-bottom: auto;
          }
        }

        .selected-devices {
          display: flex;
          flex-direction: column;

          .is-system-global {
            display: flex;
            justify-content: center;
            border-width: 2px;
            border-color: black;
            border-style: solid;
            padding: 0 2px;
            font-weight: bold;
            border-radius: 4px;
            background-color: #dee2e6;
          }

          .selected-device {
            display: flex;
            justify-content: center;
            white-space: nowrap;
            border-style: solid;
            border-width: 2px;
            border-color: black;
            padding: 0 2px;
            margin: 2px;
            border-radius: 4px;
          }
        }

        .selected-sales-orders,
        .selected-modbus-event-type-groups {
          display: flex;
          flex-direction: column;

          .selected-sales-order,
          .selected-modbus-event-type-group {
            display: flex;
            justify-content: center;
            white-space: nowrap;
            border-style: solid;
            border-width: 2px;
            border-color: black;
            padding: 0 2px;
            margin: 2px;
            border-radius: 4px;
          }
        }
      }
    }

    .event-type-icon svg {
      width: 40px;
    }
  }
}
