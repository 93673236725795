@import '../AppVars';

div.cognito-status {
  text-align: center;

  .is-link {
    a {
      margin: 4px auto auto auto;
    }

    hr {
      margin: 4px;
    }
  }
}

.users-page {
  height: 100%;
  position: relative;

  .banner-items {
    right: 0;
    top: app_scale(19);
    padding: 0;
  }

  .react-autosuggest__container {
    display: none; // not ready
    font-family: 'PT Sans', sans-serif;
    input.react-autosuggest__input {
      font-family: 'PT Sans', sans-serif;
    }
  }

  .users-page-banner {
    display: flex;
    align-items: center;
    height: app_scale(60);
    background-image: $blue-gradient;
    box-shadow: $box-shadow;

    .header-title {
      width: 100%;
      font-size: app_scale(40);
      font-weight: bold;
      color: white;
      padding-left: app_scale(40);
      line-height: 1;
    }
  }

  div.profile-picture {
    svg {
      height: 50px;
      width: 50px;
    }
  }

  .new-user-form {
    .accounts {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
