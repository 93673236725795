// sunwize color scheme
//$sunwize-blue: #005FA5;
$sunwize-blue: #67829d; //guess by JH, to match Jacob's new color scheme
$sunwize-yellow: #ffc132;
$sunwize-beige: #fffbd4;
$sunwize-red: #dc354580;
$sunwize-black: rgb(59, 59, 59);
$sunwize-lightblue: lighten($sunwize-blue, 60);
$sunwize-panel-background-color: rgb(238, 238, 238);
$sunwize-hover: #ededed;

@function app_scale($factor) {
  @return $factor * 1px;
}

$panel-iota: app_scale(0.2);
/* offset-x | offset-y | blur-radius | spread-radius | color */
$panel-shadow: 0 (10 * $panel-iota) (10 * $panel-iota) 0 rgba(0, 0, 0, 0.8),
  0 (5 * $panel-iota) (15 * $panel-iota) 0 rgba(0, 0, 0, 0.8);
$item-shadow: 0 (2 * $panel-iota) (3 * $panel-iota) 0 rgba(0, 0, 0, 0.5),
  0 (3 * $panel-iota) (8 * $panel-iota) 0 rgba(0, 0, 0, 0.5);
$light-blue-gradient: linear-gradient(45deg, rgb(168, 209, 233), rgb(189, 225, 249));
$light-blue-gradient-horizontal: linear-gradient(90deg, rgb(168, 209, 233), rgb(189, 225, 249));
$blue-gradient: linear-gradient(45deg, rgba(52, 90, 129, 1), rgba(74, 129, 161, 1));
$system-browser-width: app_scale(260);
$favorite-stroke-width: app_scale(2);
$error-gradient: linear-gradient(243deg, rgba(191, 24, 24, 1) 0%, rgba(223, 68, 68, 1) 100%);
$error-color: rgba(191, 24, 24, 1);
$warning-color: rgba(251, 215, 93, 1);
$warning-gradient: linear-gradient(245deg, rgba(251, 215, 93, 1) 0%, rgba(223, 183, 50, 1) 100%);
$blue-button-gradient: linear-gradient(to right, rgba(52, 90, 129, 1) 0%, rgba(74, 129, 161, 1) 100%);

// overrides to bootstrap variables
$blue: $sunwize-blue;
$yellow: $sunwize-yellow;

$card-scale-factor: 0.7px;

$card-width: (440 * $card-scale-factor);
$cards-box-shadow: 0 (3 * $card-scale-factor) (6 * $card-scale-factor) 0 rgba(0, 0, 0, 0.5),
  0 (5 * $card-scale-factor) (15 * $card-scale-factor) 0 rgba(0, 0, 0, 0.5);
$border-radius: (10 * $card-scale-factor);

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
