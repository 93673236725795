@import '../AppVars';

.metrics-graph {
  position: relative;
  .busy {
    margin: 175px auto;
    width: fit-content;
    .sync {
      color: $sunwize-blue;
      font-size: 70px;
    }
  }
  .jumbotron {
    text-align: center;
    padding: 2rem 1rem;
    margin-bottom: 1rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 3rem;
      margin: 0.5rem;
    }
  }
  text-align: center;
}
