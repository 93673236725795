@import '../AppVars';

.system-settings-modal {
  Button {
    background-color: transparent;
    border: none;
    color: $sunwize-blue;
    font-size: 1.1vw;
    padding: 0;
  }
}
