@import 'src/AppVars';

.events-panel {
  height: 100%;
  .scrolling-table {
    height: 100%;
    overflow: auto;
  }

  .alert {
    margin: 5px;
  }
}
