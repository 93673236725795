@import '../AppVars';

.systems-page {
  display: flex;
  height: 100%;
  flex-flow: column nowrap;
  position: relative;

  .systems-page-body {
    flex: 1 1 auto;
    min-height: 0;
  }
  .systems-page-header {
    .page-mode {
      display: flex;
      background-color: white;
      height: fit-content;
      margin-top: app_scale(23);
      margin-right: app_scale(30);
      border-radius: app_scale(4);
      margin-bottom: app_scale(10);

      button {
        display: flex;
        align-items: center;
        border: none;
        background-color: transparent;
        color: $sunwize-black;
        padding: 2px 4px;
        margin: app_scale(4);
        line-height: 1;

        &:focus {
          box-shadow: none !important;
        }

        svg {
          width: app_scale(15);
          height: app_scale(15);
          margin-right: app_scale(4);
        }

        &.cards {
          svg {
            fill: none;
            stroke: #3b3b3b;
          }

          &.selected-mode {
            color: white;
            background-color: #67819c;

            svg {
              fill: none;
              stroke: white;
            }
          }
        }

        &.list {
          svg {
            fill: #3b3b3b;
            stroke: #3b3b3b;
          }

          &.selected-mode {
            color: white;
            background-color: #67819c;

            svg {
              fill: white;
              stroke: white;
            }
          }
        }
      }
    }
  }
}
