@import '../AppVars';

.system-notes {
  margin: 0 app_scale(10);
  font-family: 'PT Sans', sans-serif;
  display: flex;
  flex-direction: column;
  height: 100%;

  hr {
    margin: 0;
  }

  .button-row {
    display: flex;
    justify-content: center;

    button {
      display: flex;
      align-items: center;
      margin: app_scale(10);

      &.new-note {
        svg {
          height: app_scale(15);
          width: app_scale(15);
          margin-right: app_scale(4);
        }
      }
    }

    .sort-button {
      color: $sunwize-black;
      background-color: white;
    }

    .new-note {
      background-image: $blue-gradient;
    }
  }

  .note-list {
    overflow-y: auto;
    flex: 1 1 auto;

    .system-note,
    .system-note-edit {
      padding: app_scale(4);
      background-color: white;
      border-width: 1px;
      border-style: solid;
      border-color: lightgrey;
      border-radius: 10px;
      box-shadow: $box-shadow;
    }

    .system-note-edit {
      margin: app_scale(4);
      width: 100%;
      display: flex;

      .system-note-form {
        flex: 1 1 auto;

        .char-count {
          font-weight: bold;
        }

        .form-group {
          margin-bottom: 0;
          margin-top: 0;
          font-size: app_scale(15);
          width: 100%;
        }

        small {
          margin: 0;
        }

        input {
          height: app_scale(25);
          font-weight: bold;
        }

        textarea,
        input {
          line-height: 1.1;
          padding: 0;

          &::placeholder {
            color: lightgray;
            font-weight: normal;
          }

          &:focus {
            &::placeholder {
              opacity: 0;
            }
            box-shadow: none !important;
            outline-width: 0 !important;
          }
        }
      }

      .button-column {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;

        .trash-can {
          svg {
            width: app_scale(15);
            height: app_scale(15);
          }

          display: flex;
          justify-content: center;
        }

        button {
          color: $sunwize-black;
          background-color: white;
          margin: app_scale(4);
          padding: 0 app_scale(2);
          border-color: $sunwize-black;
        }
      }
    }

    .system-note {
      margin: app_scale(4);

      &:hover {
        border-color: $sunwize-black;
      }

      transition: border-color 0.5s;

      .subject-line {
        display: flex;
      }

      .subject {
        font-size: app_scale(15);
        overflow: auto;
        flex: 1 1 auto;
        font-weight: bold;
      }

      .date {
        font-weight: bold;
      }

      .username {
        color: $sunwize-black;
        font-weight: bold;
        padding-right: app_scale(4);
      }

      .body {
        font-size: app_scale(15);
      }
    }
  }
}
