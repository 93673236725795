@import '../AppVars';

.user-profile-page {
  display: flex;
  height: 100%;
  flex-flow: column nowrap;
  position: relative;
  .user-profile-page-header {
    flex: 0 0 auto;
  }
  .user-profile-page-body {
    padding: app_scale(60);
    flex: 1 1 auto;
    min-height: 0;
    .user-profile-body-header {
      font-family: PosterGothicATF-Medium, sans-serif;
      font-weight: bold;
      font-size: app_scale(20);
    }
    .profile-image-wrapper {
      position: relative;
      width: app_scale(100);
      height: app_scale(100);

      .pencil-alt {
        z-index: 2;
        position: absolute;
        width: app_scale(15);
        height: app_scale(15);
        color: $sunwize-black;
        top: 5%;
        right: 5%;
        opacity: 0.5;
        transition: opacity 0.1s;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .user-profile-page-header {
    background-image: linear-gradient(90deg, #67819c, #7498af);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: app_scale(60);

    .header-title {
      font-size: app_scale(40);
      font-weight: bold;
      color: white;
      padding-left: app_scale(40);
      margin-bottom: app_scale(10);
      line-height: 1;
    }
  }
}
.profile-picture-image-cropper-modal {
  font-family: 'PT Sans', sans-serif;
  .modal-content {
    padding-top: 10px;
    border-radius: (4 * $border-radius);
    width: fit-content;
    button.cancel {
      font-size: 20px;
      font-weight: bold;
      color: $sunwize-black;
      width: fit-content;
      position: absolute;
      right: 10px;
      top: 0;
    }
    .modal-header {
      position: relative;
      margin: 0 10%;
      border: none;
      .modal-title {
        width: fit-content;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        font-size: 20px;
        font-weight: bold;
        color: $sunwize-black;
      }
    }

    .modal-body {
      flex: 0 0 auto;

      .image-edit-form {
        .cropped-row {
          border-radius: $border-radius;
          margin: 5px 0;
          display: flex;
          justify-content: space-evenly;
          align-items: center;

          border-color: lightgray;
          border-style: solid;
          border-width: 1px;
          padding: 5px 0;
          .cropped-image-panel {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1 1 auto;
            background-color: $sunwize-panel-background-color;
            border-radius: $border-radius;
            padding: 5px;
            margin: 0 5px;
            img {
              border-radius: 50%;
              border-color: gray;
              border-style: solid;
              border-width: 1px;
            }
          }

          .btn {
            background-image: $blue-gradient;
            color: grey;
            margin: 0 5px;
          }

          .btn:not([disabled]) {
            color: white;

            &:hover {
              color: $sunwize-yellow;
            }
          }
        }
      }
    }
  }
}
