@import '../AppVars';

.accounts-page {
  .banner-items {
    .add {
      padding: 0;
      color: grey;
      background-color: #ffffff00;
      border: none;
      &:hover {
        color: $sunwize-blue;
      }
    }
  }
  .new-user-form {
    .accounts {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
