.not-found {
  padding-top: 15px;
  .jumbotron {
    text-align: center;
    line-height: 0;
    padding: 2rem 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 3rem;
      margin: 0.5rem;
    }
  }
  text-align: center;
}
