@import '../AppVars';

/* to resize the icon, override the svg width:
 * .page .event-type-icon svg {
 *    width: 40px;
 *  }
 */
.event-type-icon {
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 2px;
  width: fit-content;

  svg {
    aspect-ratio: 1 / 1;
    width: 30px;
  }
  span {
    margin: 0 4px;
  }
}
