@import '../AppVars';

.system-card {
  font-family: 'PosterGothicATF', sans-serif;
  width: $card-width;
  background-image: $blue-gradient;
  box-sizing: border-box;
  border-radius: $border-radius;
  box-shadow: $cards-box-shadow;
  padding: (15 * $card-scale-factor);

  .id-row {
    border-radius: $border-radius;
    margin: 0;
    padding: 5px 0;
    background-image: $light-blue-gradient;
    position: relative;

    svg {
      position: absolute;
      height: (20 * $card-scale-factor);
      width: (20 * $card-scale-factor);
      right: (7 * $card-scale-factor);
      top: (25 * $card-scale-factor);
    }

    .bell,
    .warn {
      display: none;
      background-image: $error-gradient;
      border-radius: 5px;
      color: white;
      position: absolute;
      right: -6px;
      top: -6px;
      height: 20px;
      width: 20px;
      box-shadow: 0 3px 6px #00000087;

      svg {
        top: 3px;
        right: 3px;
      }
    }

    .warn {
      background-image: $warning-gradient;
      color: black;
    }

    &.error {
      background-image: $error-gradient;
      color: white;

      .bell {
        display: block;
      }
    }

    &.warning {
      //background-image: $warning-gradient;
      box-shadow: inset 0 0 0 3px #fedb65;

      .warn {
        display: block;
      }
    }

    .system-image-column {
      padding: 0 (10 * $card-scale-factor);
      width: fit-content;

      .system-image {
        height: (60 * $card-scale-factor);
        border-radius: 50%;

        &:hover {
          height: (100 * $card-scale-factor);
        }

        transition: height 0.5s;
      }
    }

    .system-name {
      color: $sunwize-black;
      font-size: (30 * $card-scale-factor);
      font-weight: bold;
      line-height: (35 * $card-scale-factor);
      text-transform: uppercase;
      margin-bottom: 0 !important;

      &.error {
        color: white;
      }
    }

    .location {
      color: $sunwize-black;
      font-size: (20 * $card-scale-factor);
      text-transform: uppercase;
      margin-bottom: 0 !important;
      line-height: (20 * $card-scale-factor);

      &.error {
        color: white;
      }
    }

    .last-connection {
      color: $sunwize-black;
      font-size: (10 * $card-scale-factor);

      &.error {
        font-weight: bold;
        color: $error-color;
      }
    }
  }

  .card-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-column.data {
    padding: (4 * $card-scale-factor) 0;
  }

  .card-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: (15 * $card-scale-factor);
    }
  }

  .metric {
    align-items: center;
    width: ($card-width * 0.45);
    height: (110 * $card-scale-factor);
    background-image: $light-blue-gradient;
    border-radius: $border-radius;
    display: flex;
    font-size: (5 * $card-scale-factor);
    justify-content: space-evenly;
    //color: black;
    &.load {
      svg {
        width: (70 * $card-scale-factor);
        height: (70 * $card-scale-factor);
      }
    }

    &.weather {
      .weather-strip {
        font-size: 22px;
      }
    }

    svg {
      width: (50 * $card-scale-factor);
      height: (50 * $card-scale-factor);
      color: black;
    }

    &.warning {
      //background-image: $warning-gradient;
      box-shadow: inset 0 0 0 3px #fedb65;
    }

    &.error {
      background-image: $error-gradient;

      svg {
        color: white;
      }
    }

    .row-top {
      color: $sunwize-black;
      font-size: (15 * $card-scale-factor);
      font-weight: 350;

      &.error {
        color: white;
      }
    }

    .row-middle {
      color: $sunwize-black;
      font-size: (35 * $card-scale-factor);
      line-height: (20 * $card-scale-factor);

      &.error {
        color: white;
      }
    }

    .row-bottom {
      color: $sunwize-black;
      font-size: (15 * $card-scale-factor);

      &.error {
        color: white;
      }

      &.empty {
        color: #00000000;
      }
    }
  }
}
