@import '../AppVars';

.editable-cell {
  width: 100%;
  position: relative;

  input,
  select {
    width: 100%;
    outline-color: $sunwize-blue;

    &.error {
      box-shadow: none;
      border-color: $red;
      outline: lighten($red, 30) auto 5px;
    }

    .react-select {
      max-height: 300px;
    }
  }

  .inactive {
    min-height: 12px;
    position: relative;
    padding: 0 1px;
    display: flex;

    .values {
      position: relative;
      display: flex;
      flex-direction: column;
      width: fit-content;
    }

    .pencil {
      z-index: auto;
      text-align: right;
      height: 100%;
      width: 40%;
      position: absolute;
      right: 3px;
      color: rgba(0, 0, 0, 0);

      &:hover {
        color: $sunwize-blue;
        z-index: 3;
      }
    }
  }
}

.system-cell {
  display: flex;
  align-items: center;

  a,
  button {
    margin-left: 10px;
    padding-left: 0;
    background: none;

    .icon {
      font-size: 1.1vw;
    }
  }
  .system-photo {
    border-radius: 50%;
    height: 50px;
    &:hover {
      height: 100px;
    }
    transition: height 0.5s;
  }
}

.time-since {
  &.CRITICAL {
    color: $error-color;
    font-weight: bold;
  }
}
.metrics-cell {
  .closed {
    overflow-y: hidden;
    max-height: 0;
  }

  .open {
    overflow-y: hidden;
    max-height: 20px;
  }

  .label,
  .value {
    transition: max-height 0.25s linear;
  }

  cursor: pointer;
  display: flex;

  .value {
    padding: 0 5px;
  }

  .label {
    text-align: right;
  }

  .CRITICAL {
    background: $error-color;
  }

  .WARN {
    background: $warning-color;
  }

  .CRITICAL,
  .WARN {
    &.label {
      border-left: gray solid 1px;
      border-bottom: gray solid 1px;
      border-top: gray solid 1px;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      padding-left: 5px;
    }

    &.value {
      border-right: gray solid 1px;
      border-bottom: gray solid 1px;
      border-top: gray solid 1px;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}
