@import '../AppVars';

@media all and (min-width: 180px) {
  .system-settings-form {
    .modal-header {
      position: relative;
      color: $sunwize-yellow;
      background-color: $sunwize-blue;

      .modal-title {
        width: fit-content;
        font-size: 1.25rem;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }
      .close {
        span {
          color: $sunwize-yellow;
        }

        padding: 0 1rem;
        position: absolute;
        right: 1rem;
      }
      padding-top: 15px;
    }
    .form-row {
      display: flex;
      justify-content: center;
      align-items: center;
      .form-label {
        margin-bottom: 0;
        vertical-align: center;
      }
    }
    .button-row {
      display: flex;
      .btn {
        margin: auto;
        width: auto;
        color: grey;
      }

      .btn:not([disabled]) {
        color: white;

        &:hover {
          color: $sunwize-yellow;
        }
      }
    }
  }
}
