// import bootstrap after overriding
@import 'node_modules/bootstrap/scss/bootstrap';
@import './AppVars';
// kill infernal button bax shadow when focussed
.btn:focus {
  box-shadow: none !important;
}

@font-face {
  font-family: 'Segoe UI';
  src: local('Segoe UI'), url('./fonts/Segoe-UI-Bold-Italic.ttf') format('ttf');
}

@font-face {
  font-family: 'PosterGothicATF-Medium';
  src: local('PosterGothicATF-Medium'), url('./fonts/PosterGothicATF-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'PosterGothicATF';
  src: local('PosterGothicATF'), url('./fonts/PosterGothicATF.otf') format('opentype');
}

body,
html,
#root,
.app {
  font-size: app_scale(12);
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.app {
  font-family: PosterGothicATF, sans-serif;
  height: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;

  .menu,
  .system-browser {
    flex: 0 0 auto;
  }

  .routes {
    flex: 1 1 auto;
  }

  .menu {
    z-index: 3;
    font-size: app_scale(20);
    height: 100%;
    flex: 0 0 auto;
    background-color: white;
    box-shadow: 1px 0 4px 1px rgba(0, 0, 0, 0.15);
    justify-content: space-between;
    position: relative;
    .upperGroup {
      display: flex;
      flex-direction: column;
      hr {
        opacity: 0.15;
        height: app_scale(2);
        margin: 1rem;
      }
    }

    svg {
      color: $sunwize-black;
      margin: app_scale(0) auto;
      width: app_scale(30);
      height: app_scale(30);
      border-radius: 10%;
    }

    .profile-picture svg {
      color: $sunwize-black;
      margin: 0;
      width: app_scale(60);
      height: app_scale(60);
    }

    img {
      margin: app_scale(10) app_scale(7) app_scale(20);
      width: app_scale(60);
      border-radius: 10%;
    }

    .dropdown-item {
      display: flex;
      align-items: center;

      svg {
        margin-right: app_scale(2);
        width: app_scale(20);
        height: app_scale(20);
      }
    }

    div:last-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 7px 0;
    }
    div.superuser-overlay {
      margin: 0;
    }
    a.nav-link {
      color: $sunwize-black;
      padding: app_scale(2);
      font-size: app_scale(30);
      border-radius: app_scale(5);
      margin: 0 auto app_scale(5);
      min-width: app_scale(42);

      &:hover {
        background: $sunwize-hover;
      }

      &.search {
        position: relative;

        &:after,
        &:before {
          left: 100%;
          top: 50%;
          border: solid transparent;
          content: '';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          transition: 0.25s;
        }

        &:after {
          border-color: rgba(255, 255, 255, 0);
          border-left-color: #fff;
          border-width: 0;
          margin-top: 0;
        }
        &:before {
          border-color: rgba(194, 225, 245, 0);
          border-left-color: #ededed;
          border-width: 0px;
          margin-top: -0px;
        }
      }

      &.is-active {
        background: $sunwize-blue;
        transition: 0.25s;
        &:hover {
          background: $sunwize-blue;
        }
        path {
          fill: #fff;
        }
        &.search {
          background: white;
          min-width: 100%;
          &:hover {
            background: white;
          }
          path {
            fill: $sunwize-blue;
          }
          &:after {
            border-width: 15px;
            margin-top: -15px;
          }
          &:before {
            border-width: 19px;
            margin-top: -19px;
          }
        }
      }
    }

    .dropdown-menu {
      transition: all 0.25s;

      &.show {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .dropdown-item {
          display: flex;
          justify-content: flex-start;

          svg,
          .dropdown-item-text {
            margin: 0;
            padding: app_scale(2);
          }
        }
      }
    }

    .dropdown-toggle::after {
      display: none;
    }
  }

  .routes {
    z-index: 0;
    min-width: 0;
    width: 100%;
    background-color: $sunwize-panel-background-color;

    .container-fluid {
      padding: 0;
    }
  }

  .system-browser {
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    font-size: app_scale(8);
    font-family: PosterGothicATF, sans-serif;
    box-shadow: 1px 0 4px 1px rgba(0, 0, 0, 0.15);
    position: relative;
    transition: max-width 1s;
    max-width: $system-browser-width;

    &.panel-closed {
      max-width: app_scale(15);
    }

    div.control-button {
      height: app_scale(20);
      width: app_scale(10);

      button {
        color: $sunwize-black;
        background-color: white;
        font-size: app_scale(20);
        position: absolute;
        right: app_scale(-10);
        top: app_scale(5);
        padding: 0 app_scale(6) app_scale(2) app_scale(6);
        line-height: 1;
        box-shadow: $item-shadow;
        border: none;
        border-radius: app_scale(4);
      }
    }

    .panel {
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      //margin-right: app_scale(15);

      .panel-items {
        display: flex;
        overflow: hidden;
        flex-direction: column;
        .panel-title {
          width: fit-content;
          white-space: nowrap;
          padding-left: app_scale(30);
          color: $sunwize-black;
          font-size: app_scale(25);
          font-weight: bold;
          margin: app_scale(15) 0;
        }

        .search-frame {
          flex: 0 0 auto;
          padding: app_scale(7) app_scale(13) app_scale(13);
          display: flex;
          overflow-x: hidden;
          justify-content: flex-start;
          position: relative;

          .search-icon {
            font-size: app_scale(12);
            position: absolute;
            left: app_scale(21);
            top: app_scale(12);
            color: grey;
          }

          input {
            font-family: 'PT Sans', sans-serif;
            width: 100%;
            color: $sunwize-black;
            font-size: app_scale(12);
            text-indent: app_scale(25);
            border-radius: app_scale(10);
            border: 0;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28);
            outline-width: 0;
            padding: 2px 0;

            //&:focus {
            //box-shadow: none !important;
            //}
          }
        }

        .list-group {
          flex: 1 1 auto;
          width: 100%;
          overflow-y: auto;
          margin-top: app_scale(0);
          .subHeader {
            font-size: 12px;
            margin-left: 14px;
            color: #3b3b3b;
            border-top: #e9e8e8 2px solid;
            margin-top: 5px;
            padding-top: 5px;
            margin-right: 8px;
            &.first {
              border-top: none;
              margin-top: 0;
            }
            svg {
              margin-right: 5px;
              color: #497f9f;
            }
          }

          .list-group-item {
            padding: 5px 7px 5px 0;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            background-color: transparent;
            font-size: app_scale(20);
            color: $sunwize-black;
            //min-width: app_scale(155);
            white-space: nowrap;
            line-height: 1.2;
            text-indent: app_scale(15);
            border-width: 0;
            align-items: center;
            &:hover {
              //text-decoration: underline;
              background: $sunwize-hover;
              //color:white;
            }
            .name {
              width: 100%;
              padding-right: app_scale(2);
              span {
                font-weight: bold;
                color: white;
                background-image: $blue-gradient;
              }
            }
            &.active {
              background: $sunwize-blue;
              color: white;
            }

            .favorite {
              margin-left: app_scale(9);
              margin-right: app_scale(6);
              width: app_scale(17);
              height: app_scale(17);
            }
            .bell,
            .warn {
              display: none;
              background-image: $error-gradient;
              border-radius: 5px;
              color: white;
              margin-left: 5px;
              height: 22px;
              width: 22px;
              padding: 3px;
            }
            .warn {
              background-image: $warning-gradient;
              color: black;
            }
            &.error {
              .bell {
                display: inline-block;
              }
            }
            &.warning {
              .warn {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}
//---- omni search

.app .omni {
  position: absolute;
  left: 74px;
  background: white;
  height: 100%;
  white-space: nowrap;
  margin: 0;
  z-index: 2;
  right: 100%;
  transition: 0.75s;
  overflow: hidden;
  &.open {
    right: 40px;
    box-shadow: 5px 0 5px -5px rgb(0 0 0 / 30%);
  }
}
