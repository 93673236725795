@import '../AppVars';

.image-edit {
  border-radius: $border-radius;
  border-color: lightgray;
  border-style: solid;
  border-width: 1px;
  padding: 5px;

  .image-cropper {
    position: relative;
    height: 400px;
    width: 400px;

    .reactEasyCrop_Container {
      border-radius: $border-radius;
    }
  }

  .edit-controls {
    padding: 5px 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: bold;

    .slider {
      padding-left: 5px;
      padding-right: 5px;
      flex: 1 1 auto;

      input[type='range'] {
        &::-webkit-slider-thumb {
          background-image: $blue-gradient;
        }
      }
    }

    label {
      color: white;
      background-image: $blue-gradient;

      &:hover {
        color: $sunwize-yellow;
      }
    }
  }
}
