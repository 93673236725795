@import '../AppVars';

.async-device-select {
  .choice {
    display: flex;
    font-size: 16px;
    align-items: center;
  }
  .async-device-select__indicator,
  .async-device-select__value-container {
    padding: 0;
  }
  .async-device-select__control {
    min-height: 0;
  }
}
