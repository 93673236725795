@import '../AppVars';

.severity-selector {
  width: 130px;
  .choice {
    display: flex;
    font-size: 16px;
    align-items: center;
  }
  .bell,
  .check-mark,
  .exclamation-point,
  .info-icon {
    width: 28px;
  }
  .severity-selector__input-container {
    display: none;
  }
  .severity-selector__indicator,
  .severity-selector__value-container {
    padding: 0;
  }
  .severity-selector__control {
    min-height: 0;
  }
}
