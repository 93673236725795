@import '../AppVars';

.new-user-form {
  .modal-header {
    position: relative;
    color: $sunwize-yellow;
    background-color: $sunwize-blue;

    .modal-title {
      display: flex;
      justify-content: center;
      width: 100%;
      font-size: 1.25rem;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }

    .close {
      span {
        color: $sunwize-yellow;
      }

      padding: 0 1rem;
      position: absolute;
      right: 1rem;
    }
  }

  .modal-body {
    .accounts.form-group,
    .devices.form-group {
      width: 100%;
    }
    .account-select__multi-value,
    .device-select__multi-value {
      background-color: $sunwize-lightblue;
    }
  }
  .modal-footer {
    .loader-button {
      margin: auto;
      width: auto;
    }
  }
}
