@import '../AppVars';

/* the weather strip is scaled based on the inherited font-size,
 * which is 1em
 */
.weather-strip {
  display: flex;
  align-items: center;
  color: $sunwize-black;
  background-color: white;
  border-radius: $border-radius;

  .weather-image {
    width: 1.5em;
    height: 1.5em;
    margin-left: -0.2em;
  }

  .temp {
    display: flex;
    justify-content: center;
    color: $sunwize-black;
    width: 1.6em; // wide enough for two digits
    padding: 0 0.1em 0 0;
    margin-left: -0.1em;
  }

  .weather-data-column {
    display: flex;
    flex-direction: column;
    font-size: 0.3em;
    align-items: flex-start;
    padding: 0.2em;
    margin-right: 0.4em;
    margin-left: 0.4em;

    .rain {
      color: $sunwize-black;
      margin: 0;
    }

    .humidity {
      color: $sunwize-black;
      margin: 0;
    }
    .pressure {
      color: $sunwize-black;
    }
  }
}
