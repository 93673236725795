@import 'src/AppVars';

.system-overview-panel {
  height: 100%;
  margin-left: app_scale(60);
  -moz-appearance: app_scale(60);
  appearance: app_scale(60);

  .heading {
    font-size: app_scale(30);
    font-weight: bold;
    color: $sunwize-black;
    margin-bottom: app_scale(15);
    &:not(:first-of-type) {
      margin-top: app_scale(30);
    }
  }

  .sub-heading {
    font-size: app_scale(15);
    font-weight: bold;
  }

  .power-flow-chart {
    position: relative;

    svg {
      margin: 0 app_scale(40);
      height: 82px;
      width: 475px;
    }

    .array,
    .battery,
    .load {
      position: absolute;
      top: app_scale(14);
      display: flex;
      flex-direction: column;
      align-items: center;
      .power-name {
        color: white;
        font-size: app_scale(12);
      }
      .power-value {
        color: white;
        font-size: app_scale(20);
      }
    }

    .array {
      left: app_scale(101);
    }

    .battery {
      left: app_scale(270);
    }

    .load {
      left: app_scale(445);
    }
  }

  .system-design-info {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .spec {
      margin: 0 app_scale(10) app_scale(8) 0;
      border-radius: 8px;
      box-shadow: $box-shadow;
      padding: app_scale(4);
      background-image: linear-gradient(90deg, #eeeeee, white);

      .spec-name {
        font-size: app_scale(20);
        font-weight: bold;
        white-space: nowrap;
      }
      .spec-value {
        font-family: 'PT Sans', sans-serif;
        font-size: app_scale(18);
      }
    }
  }
}
