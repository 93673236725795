@import '../AppVars';

.anchor-button {
  font-size: 12px;
  color: $sunwize-blue;
  background-color: transparent;
  border: none;
  &[disabled] {
    color: grey;
  }

  &:hover:not([disabled]) {
    text-decoration: underline;
    color: #52687e;
  }
}
