@import '../AppVars';

.deployment-form {
  position: relative;
  .check-mark {
    color: green;
    margin-right: 4px;
  }

  .no-error {
    background-color: $sunwize-lightblue;
  }

  .connected-system {
    border-width: 1px;
    border-radius: 2px;
    border-style: solid;
  }

  .modal-header {
    position: relative;
    color: $sunwize-yellow;
    background-color: $sunwize-blue;

    .modal-title {
      width: fit-content;
      font-size: 1.25rem;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }

    .close {
      span {
        color: $sunwize-yellow;
      }

      padding: 0 1rem;
      position: absolute;
      right: 1rem;
    }
  }

  .modal-body {
    .connection-state,
    .ubuntu-host-connection-status,
    .usb-connected-system-present,
    .usb-connected-system-not-present,
    .deploy-system,
    .specifics-storage,
    .user-error-message {
      span.status {
        margin: 0 4px;
        font-weight: bold;
        color: $sunwize-blue;

        &.error {
          color: $error-color;
        }
        &.warn {
          color: darken($warning-color, 20);
        }
      }

      .icon {
        font-size: 12px;
        margin: 0 4px;

        &.times,
        &.exclamation {
          margin: 0 5px;
          font-size: 14px;
          color: $sunwize-red;
        }

        &.warn {
          margin: 0 5px;
          font-size: 14px;
          color: darken($warning-color, 20);
        }

        &.exclamation {
          margin: 0 2px;
        }

        &.network-wired {
          color: lightgreen;
          top: -41px;
          right: 20px;
          position: absolute;
          &.no-ip {
            color: red;
            animation: blink 1s infinite;
          }
        }
        &.signal {
          top: -41px;
          right: 0;
          position: absolute;
        }
        &.check,
        &.sync,
        &.good.signal {
          color: lightgreen;
        }

        &.bad.signal {
          animation: blink 1s infinite;
          color: lightgrey;
        }
      }
    }
    .usb-connected-system-present {
      display: flex;
    }
  }
  @keyframes blink {
    from,
    to {
      color: #00000000;
    }
    50% {
      color: red;
    }
  }

  .modal-footer {
    .btn {
      margin: auto;
      width: auto;
      color: grey;
    }

    .btn:not([disabled]) {
      color: white;
      &:hover {
        color: $sunwize-yellow;
      }
    }
  }
}
