@import '../AppVars';

@media all and (min-width: 180px) {
  .image-cropper-modal {
    font-family: 'PT Sans', sans-serif;
    .modal-content {
      padding-top: 10px;
      border-radius: (4 * $border-radius);
      width: fit-content;
      button.cancel {
        font-size: 20px;
        font-weight: bold;
        color: $sunwize-black;
        width: fit-content;
        position: absolute;
        right: 10px;
        top: 0;
      }
      .modal-header {
        position: relative;
        margin: 0 10%;
        border: none;
        .modal-title {
          width: fit-content;
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          font-size: 20px;
          font-weight: bold;
          color: $sunwize-black;
        }
      }

      .modal-body {
        flex: 0 0 auto;

        .image-edit-form {
          .cropped-row {
            border-radius: $border-radius;
            margin: 5px 0;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            border-color: lightgray;
            border-style: solid;
            border-width: 1px;
            padding: 5px 0;
            .cropped-image-panel {
              flex: 1 1 auto;
              background-image: $blue-gradient;
              border-radius: $border-radius;
              padding: 5px;
              margin: 0 5px;
              img {
                border-radius: 50%;
                border-color: gray;
                border-style: solid;
                border-width: 1px;
              }
              .busy {
                margin: 10px;
                width: 40px;
                height: 40px;
              }
            }

            .btn {
              background-image: $blue-gradient;
              color: grey;
              margin: 0 5px;
            }

            .btn:not([disabled]) {
              color: white;

              &:hover {
                color: $sunwize-yellow;
              }
            }
          }
        }
      }
    }
  }

  Button {
    background-color: transparent;
    border: none;
    color: $sunwize-blue;
    font-size: 1.1vw;
    padding: 0;
  }
}
