@import '../AppVars';

.system-cards {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .panelGroupWrapper {
    display: flex;
    flex-direction: column;
    //margin: 20px;
    //background: #c3cfd7;
    //border-radius: 10px;
  }
  .group-header,
  .divider {
    flex: 0 0 auto;
  }
  .card-panel {
    flex: 1 1 auto;
  }

  .group-header {
    color: $sunwize-black;
    font-size: app_scale(30);
    font-family: PosterGothicATF-Medium, sans-serif;
    margin: 0;
    padding: app_scale(20) app_scale(40);
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #eeeeee;

    svg {
      fill: $sunwize-black;
      width: app_scale(30);
      height: app_scale(30);
      margin-right: app_scale(10);
    }
    button {
      float: right;
    }
    span {
      cursor: pointer;
      .fa-chevron-down {
        -moz-transition: all 0.25s linear;
        -webkit-transition: all 0.25s linear;
        transition: all 0.25s linear;
        margin-left: 10px;
      }
      &.closed {
        .fa-chevron-down {
          -ms-transform: rotate(-180deg);
          -moz-transform: rotate(-180deg);
          -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
        }
      }
    }
  }

  .card-panel {
    //overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    margin-left: app_scale(20);
    //min-height: app_scale(10);
    transition: min-height 1s;
    align-items: flex-start;
    align-content: flex-start;

    //&:hover {
    //min-height: app_scale(500);
    //}
    .btn,
    .btn:hover {
      padding: 0;
      background-color: transparent;
      border: none;
      .system-card {
        margin: app_scale(15);
      }
    }
  }
  .divider {
    min-height: 1px;
    background-color: grey;
    margin: app_scale(10) app_scale(40);
  }
}

//------------------------------------------

.sticky-content {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #ffe4c4;
  color: #ffffff;
  padding: 3rem 20px;
}
.sticky-content-title {
  margin: 0;
}
.placeholder-content {
  //height: 1000px;
  background-color: yellow;
  background: linear-gradient(180deg, rgba(255, 255, 0, 1) 0%, rgba(255, 140, 0, 1) 100%);
}
.placeholder-title {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 20px;
}
.placeholder-separator {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
