@import '../AppVars';
@import 'src/components/Favorite';

@function scale_($factor) {
  @return app_scale($factor * 1);
}

.system-detail {
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .system-detail-banner,
  .system-detail-button-bar {
    flex: 0 0 auto;
  }

  .selected-panel {
    flex: 1 1 auto;
  }

  .system-detail-banner {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    padding: scale_(10) 0.5%;
    background-image: $blue-gradient;
    box-shadow: $box-shadow;

    .system-image-wrapper {
      position: relative;
      width: scale_(100);
      height: scale_(100);
      margin: 0 1%;

      .system-image {
        border-radius: 50%;
        width: scale_(100);
        height: scale_(100);
        border: none;
      }

      .pencil-alt {
        padding: 25%;
        position: absolute;
        border-radius: 50%;
        width: scale_(100);
        height: scale_(100);
        color: gray;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.5s;
      }
    }

    .system-image-wrapper.can-edit:hover {
      .pencil-alt {
        opacity: 0.75;
      }
    }

    .system-detail-status-bar {
      flex: 1 1 auto;
      display: flex;
      width: 80%;
      justify-content: space-between;
      align-items: center;
      background-image: linear-gradient(90deg, #aec6df, #b6d6ec);
      border-radius: app_scale(12);

      .connection,
      .time {
        color: rgb(59, 59, 59);
        display: flex;
        flex-direction: column;
        justify-content: center;

        .error {
          color: $error-color;
        }
      }

      .left-side {
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .system-name {
          flex: 0 1 auto;
          color: rgb(59, 59, 59);
          border-radius: 8px;
          font-size: scale_(45);
          font-weight: bold;
          padding: 0 scale_(9);
          line-height: 1;
          white-space: nowrap;
        }
      }

      .right-side {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .weather-strip,
        .local-time {
          margin: app_scale(4) scale_(10);
        }

        .weather-strip {
          font-size: app_scale(35);
          border-radius: app_scale(12);
        }

        .time {
          white-space: nowrap;
        }

        svg {
          width: scale_(30);
          height: scale_(30);
          margin: 0 scale_(10);
        }
      }
    }
  }

  .system-detail-button-bar {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding-left: scale_(55);
    border-bottom: #80808050 1px solid;

    button {
      color: $sunwize-black;
      background-color: transparent;
      padding: 0 scale_(2);
      font-size: scale_(20);
      border: none;
      box-shadow: none;
      border-right: 0;
      margin-right: scale_(20);

      &.is-active,
      &:hover {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  .selected-panel {
    overflow: hidden;

    .selected-panel-body {
      color: rgba(59, 59, 59, 0.5);
      font-size: 80pt;
      width: fit-content;
      line-height: 1;
      margin-top: 80pt;
      transform: rotate(-10deg);
    }
  }
}
