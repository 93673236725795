@import '../AppVars';

.favorite {
  display: flex;
  //&.hover-mode {
  $favorite-transition-interval: 0.25s;
  transition: opacity $favorite-transition-interval linear;
  .svg-favorite-icon {
    fill: url(#linear-gradient-svg-favorite);
    transition: fill-opacity $favorite-transition-interval linear;

    .svg-favorite-icon-path {
      stroke: $sunwize-blue;
      transition: stroke-opacity $favorite-transition-interval linear;
    }
  }

  //&.hover-enabled:hover {
  //  /* non fav, w/hover */
  //  .svg-favorite-icon {
  //    fill-opacity: 1;
  //
  //    .svg-favorite-icon-path {
  //      stroke-opacity: 0;
  //    }
  //  }
  //}
  //
  .svg-favorite-icon {
    /* non fav, w/o hover */
    fill-opacity: 0;

    .svg-favorite-icon-path {
      stroke-opacity: 1;
    }
  }

  &.is-favorite {
    /* fav w/o hover */
    .svg-favorite-icon {
      fill-opacity: 1;

      .svg-favorite-icon-path {
        stroke-opacity: 0;
      }
    }

    //&.hover-enabled:hover {
    //  /* fav w/hover */
    //  .svg-favorite-icon {
    //    fill-opacity: 0;
    //
    //    .svg-favorite-icon-path {
    //      stroke-opacity: 1;
    //    }
    //  }
    //}
  }

  &.hidden {
    opacity: 10%;

    &:hover {
      opacity: 1;
    }
  }
  //}
}
