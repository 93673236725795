@import '../AppVars';
$chooser-font: app_scale(15);

.table-column-chooser {
  .group-choosers {
    display: flex;
    padding: 1px;
    justify-content: space-around;
    button {
      font-size: $chooser-font;
      color: $sunwize-black;
      border: none;
      &:hover {
        text-decoration: underline;
        font-weight: bold;
        background-color: transparent;
      }
    }
  }

  .selectors {
    max-height: 20rem;
    height: auto;
    overflow-x: hidden;

    .dropdown-item {
      display: flex;
      padding: 1px 4px;
      justify-content: flex-start;
      align-items: center;
      font-size: $chooser-font;

      .checkbox {
        color: $sunwize-black;
        border-color: $sunwize-black;
        background-color: white;
        font-size: $chooser-font;
        font-weight: bold;
        padding: 0 app_scale(4);
        -moz-appearance: app_scale(4);
        appearance: app_scale(4);
        &.hidden {
          color: white;
        }
      }

      .column-name {
        color: $sunwize-black;
        padding-left: 10px;
      }

      &:hover {
        background-color: $gray-200;

        .checkbox {
          font-weight: bold;

          &.hidden {
            background-color: white;
            color: white;
          }
        }
        .column-name {
        }
      }
    }
  }
}
