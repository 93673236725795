@import '../AppVars';

.metrics {
  .attribute-chooser,
  .graph-interval {
    border-width: 1px;
    border-color: lightgray;
    border-style: solid;
    border-radius: app_scale(4);
    margin: app_scale(5) auto;
    width: fit-content;
  }

  button {
    color: $sunwize-black;
    background-color: transparent;
    padding: 0 app_scale(2);
    font-size: app_scale(10);
    border: none;
    box-shadow: none;
    border-right: 0;
    margin-right: app_scale(20);

    &.selected,
    &:hover {
      font-weight: bold;
      text-decoration: underline;
      color: $sunwize-black;
      background-color: transparent;
    }
  }

  .download.button-overlay,
  .clipboard-copy.button-overlay {
    position: absolute;
    top: app_scale(10);

    button {
      background-color: transparent;
      border: none;
      outline-width: 0;
    }
    svg {
      height: app_scale(18);
      width: app_scale(18);
      padding: app_scale(2);

      &:hover {
        border-bottom-color: $sunwize-black;
        border-bottom-width: app_scale(2);
        border-bottom-style: solid;
      }
    }
  }

  .clipboard-copy.button-overlay {
    right: app_scale(40);
  }

  .download.button-overlay {
    right: app_scale(70);
  }

  .device-select {
    margin: 0 auto;
  }

  #metrics-clipboard-message.popover {
    top: app_scale(8);
    border-color: $sunwize-blue;

    .popover-body {
      color: $sunwize-blue;
      font-size: 1vw;
      padding: app_scale(2);
      margin: 0;
    }

    .arrow {
      display: none;
    }
  }
}
