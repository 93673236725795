@import '../AppVars';

.system-permissions {
  .user-node::before {
    content: '';
    background-image: url('../Users.svg');
    background-size: app_scale(30) app_scale(30);
    width: app_scale(30);
    height: app_scale(30);
  }
}
