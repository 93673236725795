@import '../App';

.form-page {
  font-family: 'PT Sans', sans-serif;
  input {
    font-size: 16px !important; // override bootstrap
  }
  $column-width: 35vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background-position: -11px -18px;
  background-size: 105%;
  background-color: #eeeeee;
  background-repeat: no-repeat;
  .login-pic {
    height: 177px;
    width: 100%;
    background-image: url('./roman.jpg');
    border-radius: 6pt 6pt 0 0;
    background-size: cover;
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  }
  //height: 100vh;
  position: relative;
  &.container-fluid {
    background-image: url('./form-page-background.png');
    background-position: bottom right;
    background-size: 40%;
    height: 100%;
  }

  .sunwize-logo {
    position: absolute;
    bottom: 3vh;
    left: 1vw;
    width: 150px;
  }

  .jacobi-wheel {
    margin: 0 auto;
    width: $column-width;
  }

  $column-width: 35vw;

  .form-page {
    margin: 1vh auto 15vh;
    //padding: 177px 10px 10px 10px;
    width: $column-width;
    border-radius: 6pt;
    background-color: white;

    .title {
      font-family: 'PosterGothicATF', sans-serif;
      font-size: 20pt;
      font-weight: 800;
      width: fit-content;
      margin: 0 auto 10px;
    }

    .form-group {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin: 0 20px;

      input,
      .form-label {
        font-size: 16px;
      }

      input {
        margin-right: 30px;
      }

      .form-label {
        margin: 10px 10px 10px 0;
        width: 70px;
      }
    }

    .button-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 20px;

      button {
        width: fit-content;
        color: white;
        background: linear-gradient(45deg, rgba(52, 91, 130, 1), rgba(74, 129, 161, 1));
        padding: 10px 20px;
        font-size: 16px;
      }
      a {
        font-size: 16px;
      }
    }

    p {
      width: 80%;
      margin: 0 auto 10px;
    }
  }
}
